import { styled } from '@mui/material';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { ReactNode } from 'react';
import CloseSnackbarButton from './CloseSnackbarButton';

const StyledNotistackProvider = styled(NotistackProvider)`
    .MuiSnackbarContent-message,
    .MuiSnackbarContent-action {
        color: #fff;
    }
`;

type SnackbarProviderProps = {
    children: ReactNode;
};

function SnackbarProvider({ children }: SnackbarProviderProps) {
    return (
        <StyledNotistackProvider
            maxSnack={3}
            action={<CloseSnackbarButton />}
            autoHideDuration={null}
        >
            {children}
        </StyledNotistackProvider>
    );
}

export default SnackbarProvider;
