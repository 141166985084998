import { GET, POST } from '../types/requestMethod';

type HTTPRequestProps = {
    url?: string;
    path?: string;
    origin?: string;
    body?: Record<never, never>;
    query?: string;
    variables?: string;
    params?: Record<string, never>;
    headers?: object;
    method?: string;
    accessToken?: string;
};

const defaultHeaders = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: 'application/json',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
};

const processResponse = (response: Response) => {
    const contentType = response.headers.get('content-type');

    if (!contentType) {
        return Promise.resolve();
    }

    if (contentType.toLowerCase().indexOf('json') >= 0) {
        return response.json().catch(() => Promise.resolve()); // TODO probably improve the .catch
    }

    if (contentType.toLowerCase().indexOf('text') >= 0) {
        return response.text();
    }

    return null;
};

const checkStatus = (response: Response) => {
    const { status } = response;

    if (status >= 200 && status < 300) {
        return processResponse(response);
    }

    return processResponse(response)?.then(result => {
        console.error(result);

        return Promise.reject(result);
    });
};

export const buildUrl = (path?: string, origin?: string, params?: Record<string, never>) => {
    if (params) {
        const queryParams = Object.keys(params).map((key: string) => `${key}=${params[key] || ''}`);

        return `${origin || ''}${path || ''}?${queryParams.join('&')}`;
    }

    return `${origin || ''}${path || ''}`;
};

const HTTPRequest = ({
    url,
    path,
    origin,
    body: initBody,
    query,
    variables,
    params,
    headers,
    method,
    accessToken,
}: HTTPRequestProps) => {
    const theUrl = url || buildUrl(path, origin, params);
    const body = initBody
        ? initBody === Object(initBody)
            ? JSON.stringify(initBody)
            : initBody
        : query
        ? { query, variables }
        : null;

    const init: RequestInit = {
        method: method || (body ? POST : GET),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        body,
        headers: accessToken
            ? { ...defaultHeaders, Authorization: accessToken, ...headers }
            : { ...defaultHeaders, ...headers },
    };

    if (init.method === GET) {
        console.debug(`fetching ${theUrl}`);
    } else {
        console.debug(`posting ${theUrl} ${JSON.stringify(body)}`);
    }

    return fetch(theUrl, init).then(checkStatus);
};

export default HTTPRequest;
