import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-fs-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import nl from './locales/nl.json';
import en from './locales/en.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import it from './locales/it.json';
import lt from './locales/lt.json';
import 'intl';
import 'intl/locale-data/jsonp/nl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/fr-CH';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/pt';
import 'intl/locale-data/jsonp/it';
import 'intl/locale-data/jsonp/lt';

export const defaultNS = 'translations';

export const resources = {
    nl: { translations: nl },
    en: { translations: en },
    de: { translations: de },
    fr: { translations: fr },
    es: { translations: es },
    pt: { translations: pt },
    it: { translations: it },
    lt: { translations: lt },
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: localStorage.getItem('language') || navigator.language || 'en',
        resources,
        ns: [defaultNS],
        defaultNS,
    });

export default i18n;
