import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function CloseSnackbarButton() {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton aria-label="Close notification" color="inherit" onClick={() => closeSnackbar()}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );
}

export default CloseSnackbarButton;
