import { styled } from '@mui/material';
import { forwardRef, createRef, RefObject, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReceiptItem from './ReceiptItem';
import { Product } from '../../../types/purchase';
import { parsePrice } from '../../../util/function';

const ReceiptItemContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    column-gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;
`;

const StyledParagraph = styled('p')<{ indent?: boolean }>`
    margin-bottom: 0;
    margin-top: 0;
    margin-left: ${({ indent, theme }) => (indent ? theme.spacing(2) : 0)};
    font-family: Roboto Mono, serif;
    font-size: 12px;
    line-height: 1em;
    overflow-wrap: anywhere;
`;

const PriceContainer = styled('span')<{ minWidth?: number }>`
    display: flex;
    flex-direction: row;
    min-width: ${({ minWidth }) => minWidth}px;
    gap: ${({ theme }) => theme.spacing(1)};
    justify-content: right;
    text-wrap: nowrap;
`;

const BrutoPrice = styled(StyledParagraph)`
    text-decoration: line-through;
    text-decoration-color: ${({ theme }) => theme.palette.text.strikethrough};
`;

const DashedLines = styled(ReceiptItem)`
    white-space: nowrap;
    overflow: hidden;
    border-left: ${({ theme }) => theme.spacing(1)};
    border-right: ${({ theme }) => theme.spacing(1)};
`;

const ProductItemContainer = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(1.5)};
    margin: ${({ theme }) => theme.spacing(0.5, 0, 2, 0)};
`;

const StyledReceiptItem = styled(ReceiptItem)`
    font-weight: bold;
`;

type ProductItemRowProps = {
    name?: string;
    parsedNetto: string | false;
    parsedBruto?: string;
    indent?: boolean;
    maxPriceContainerWidth: number;
};

const ProductItemRow = forwardRef(
    (
        { name, parsedNetto, parsedBruto, indent, maxPriceContainerWidth }: ProductItemRowProps,
        ref
    ) => {
        if (!name || !parsedNetto) {
            return null;
        }

        return (
            <ReceiptItemContainer>
                <StyledParagraph indent={!!indent}>{name}</StyledParagraph>
                <PriceContainer
                    ref={ref as RefObject<HTMLDivElement>}
                    minWidth={maxPriceContainerWidth}
                >
                    {parsedNetto !== parsedBruto && <BrutoPrice>{parsedBruto}</BrutoPrice>}
                    <StyledParagraph>{parsedNetto}</StyledParagraph>
                </PriceContainer>
            </ReceiptItemContainer>
        );
    }
);

type ProductItemProps = {
    product: Product;
    currencyCode: string;
    maxPriceContainerWidth: number;
};

const ProductItem = forwardRef(
    ({ product, currencyCode, maxPriceContainerWidth }: ProductItemProps, ref) => {
        const { i18n } = useTranslation();

        const { name, packageName, depositName } = product;
        const { price, originalPrice } = product.price;
        const { price: packagePrice } = product.packagePrice ?? {};
        const { price: depositPrice } = product.depositPrice ?? {};

        // Parse prices to currency of selected language locale
        const parsedBruto = parsePrice(originalPrice, currencyCode, i18n) || '';
        const parsedNetto = parsePrice(price, currencyCode, i18n) || '';
        const parsedPackageNetto =
            packagePrice != null && parsePrice(packagePrice, currencyCode, i18n);
        const parsedDepositNetto =
            depositPrice != null && parsePrice(depositPrice, currencyCode, i18n);

        return (
            <>
                <ProductItemRow
                    ref={ref}
                    name={name}
                    parsedNetto={parsedNetto}
                    parsedBruto={parsedBruto}
                    maxPriceContainerWidth={maxPriceContainerWidth}
                />
                <ProductItemRow
                    indent
                    ref={ref}
                    name={depositName}
                    parsedNetto={parsedDepositNetto}
                    maxPriceContainerWidth={maxPriceContainerWidth}
                />
                <ProductItemRow
                    indent
                    ref={ref}
                    name={packageName}
                    parsedNetto={parsedPackageNetto}
                    maxPriceContainerWidth={maxPriceContainerWidth}
                />
            </>
        );
    }
);

type ReceiptItemProps = {
    products: [Product];
    currencyCode: string;
};

function ReceiptProductItems({ products, currencyCode }: ReceiptItemProps) {
    const [maxPriceContainerWidth, setMaxPriceContainerWidth] = useState<number>(0);

    const { t } = useTranslation();

    // Create as many refs as there are products
    const refs = products.map(_ => createRef<HTMLDivElement>());

    // Min width for all price container will share the same widest container width.
    // This is to prevent weird visual wrapping of product names.
    useLayoutEffect(
        () => setMaxPriceContainerWidth(Math.max(...refs.map(e => e.current?.offsetWidth || 0))),
        [refs]
    );

    return (
        <>
            <StyledReceiptItem
                name={t('receiptPageProductColumn')}
                value={t('receiptPagePriceColumn')}
            />
            <DashedLines name={'-'.repeat(100)} />
            <ProductItemContainer>
                {products?.map((p, i) => (
                    <ProductItem
                        // Use index as key because list will never be re-ordered
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        ref={refs[i]}
                        product={p}
                        maxPriceContainerWidth={maxPriceContainerWidth}
                        currencyCode={currencyCode}
                    />
                ))}
            </ProductItemContainer>
        </>
    );
}

export default ReceiptProductItems;
