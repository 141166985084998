import { styled } from '@mui/material';

const ReceiptItemContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledParagraph = styled('p')`
    margin: 0;
    font-family: Roboto Mono, serif;
    font-size: 12px;
    text-align: left;
    align-self: flex-start;
    flex: 33%;
`;

const StyledCenterParagraph = styled(StyledParagraph)`
    text-align: center;
`;

const StyledRightParagraph = styled(StyledParagraph)`
    text-align: right;
`;

type ReceiptVatItemProps = {
    left?: string;
    center?: string;
    right?: string;
    className?: string;
};

function ReceiptVatItem({ left = '', center = '', right = '', className }: ReceiptVatItemProps) {
    return (
        <ReceiptItemContainer className={className}>
            <StyledParagraph>{left}</StyledParagraph>
            <StyledCenterParagraph>{center}</StyledCenterParagraph>
            <StyledRightParagraph>{right}</StyledRightParagraph>
        </ReceiptItemContainer>
    );
}

export default ReceiptVatItem;
