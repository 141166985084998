import { styled } from '@mui/material';

const ReceiptItemContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    column-gap: ${({ theme }) => theme.spacing(1)};

    p:nth-of-type(2) {
        // Let the right hand side not wrap
        white-space: nowrap;
    }
`;

const StyledParagraph = styled('p')`
    margin: 0;
    font-family: Roboto Mono, serif;
    font-size: 12px;
`;

const StyledRightParagraph = styled(StyledParagraph)`
    text-align: right;
`;

type ReceiptItemProps = {
    name?: string;
    value?: string;
    className?: string;
};

function ReceiptItem({ name = '', value = '', className }: ReceiptItemProps) {
    return (
        <ReceiptItemContainer className={className}>
            <StyledParagraph>{name}</StyledParagraph>
            <StyledRightParagraph>{value}</StyledRightParagraph>
        </ReceiptItemContainer>
    );
}

export default ReceiptItem;
