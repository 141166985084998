import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    Theme,
    useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Language } from '../../types/languages';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledFormControl = styled(FormControl)`
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
`;

const StyledSelect = styled(Select)`
    min-width: ${({ theme }) => theme.spacing(20)};

    .MuiInputBase-input {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const StyledImg = styled('img')`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

function LanguageSelector() {
    const { t, i18n } = useTranslation();
    const theme: Theme = useTheme();

    const [selection, setSelection] = useState(i18n.resolvedLanguage);

    const { width } = useWindowDimensions();

    const languages: readonly Language[] = [
        { country: 'NL', language: 'nl', dayJs: 'nl', alt: 'Nederlands' },
        { country: 'GB', language: 'en', dayJs: 'en-gb', alt: 'English' },
        { country: 'DE', language: 'de', dayJs: 'de', alt: 'Deutsch' },
        { country: 'FR', language: 'fr', dayJs: 'fr', alt: 'Français' },
        { country: 'ES', language: 'es', dayJs: 'es', alt: 'Español' },
        { country: 'PT', language: 'pt', dayJs: 'pt', alt: 'Português' },
        { country: 'IT', language: 'it', dayJs: 'it', alt: 'Italiano' },
        // dayjs LT not supported
        { country: 'LT', language: 'lt', dayJs: 'en-gb', alt: 'Lietuvių' },
    ];

    dayjs.extend(localizedFormat);

    const handleLanguageSelection = (event: SelectChangeEvent<typeof selection>) => {
        const {
            target: { value },
        } = event;

        i18n.changeLanguage(value).then(() => {
            i18n.options.lng = value;
            setSelection(value);
            localStorage.setItem('language', value);
            dayjs.locale(value);
        });
    };

    // this function sets the document title based on the selection of the language.
    useEffect(() => {
        document.title = t('landingTitle');
    }, [t, selection]);

    return (
        <StyledFormControl>
            <InputLabel
                id="select-language-label"
                size={
                    width < theme.breakpoints.values.sm + parseInt(theme.spacing(4), 10)
                        ? 'small'
                        : 'normal'
                }
            >
                {t('languagePageFoldout')}
            </InputLabel>
            <StyledSelect
                id="select-language"
                size={
                    width < theme.breakpoints.values.sm + parseInt(theme.spacing(4), 10)
                        ? 'small'
                        : 'medium'
                }
                labelId="select-language-label"
                label={t('languagePageFoldout')}
                value={selection}
                onChange={handleLanguageSelection}
            >
                {languages.map(e => (
                    <MenuItem key={e.language} value={e.language}>
                        <StyledImg
                            loading="lazy"
                            width="20"
                            height="13.5"
                            src={`https://flagcdn.com/w20/${e.country.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${e.country.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        <ListItemText primary={e.alt} />
                    </MenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
}

export default LanguageSelector;
