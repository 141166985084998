// Parse price to a string and include currency symbol and format in selected locale
const parsePrice = (price?: number, currency?: string, i18n?: { language?: string }) => {
    if (currency && i18n?.language && typeof price === 'number') {
        const parts = new global.IntlPolyfill.NumberFormat(i18n.language, {
            style: 'currency',
            currency,
        }).formatToParts(1000.1);

        const decimalLength = (
            parts.find(({ type }: { type: string }) => type === 'fraction')?.value ?? ''
        ).length;

        const parsedPrice = price / 10 ** decimalLength;

        // explicitly use the polyfill from the intl package
        return new global.IntlPolyfill.NumberFormat(i18n.language, {
            style: 'currency',
            currency,
        }).format(parsedPrice) as string;
    }
    return '';
};

export { parsePrice };
