import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { VatAmounts } from 'src/types/purchase';
import ReceiptVatItem from './ReceiptVatItem';
import { parsePrice } from '../../../util/function';
import ReceiptItem from './ReceiptItem';

const ReceiptVatItemHeader = styled(ReceiptVatItem)`
    font-weight: bold;
`;
const StyledReceiptVatItem = styled(ReceiptVatItem)`
    margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const StyledReceiptItem = styled(ReceiptItem)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

type ReceiptVatsProps = {
    totalVatAmount: number;
    totalPriceExVat: number;
    totalPrice: number;
    vatAmounts: [VatAmounts];
    currencyCode: string;
};

function ReceiptVats({
    totalVatAmount,
    totalPriceExVat,
    totalPrice,
    vatAmounts,
    currencyCode,
}: ReceiptVatsProps) {
    const { t, i18n } = useTranslation();

    return (
        <>
            <StyledReceiptItem
                name={t('receiptPagePriceExVAT')}
                value={parsePrice(totalPriceExVat, currencyCode, i18n)}
            />
            <ReceiptVatItemHeader
                left={t('receiptPageVAT')}
                center={t('receiptPageVATOver')}
                right={t('receiptPageVATAmount')}
            />
            {vatAmounts.map(e => (
                <ReceiptVatItem
                    key={e.vatPercentage}
                    left={`${String(e.vatPercentage)}%`}
                    center={`${parsePrice(e.amountForVat, currencyCode, i18n)}`}
                    right={`${parsePrice(e.vatAmount, currencyCode, i18n)}`}
                />
            ))}
            <StyledReceiptVatItem
                left={t('receiptPageTotal')}
                center={parsePrice(totalPrice, currencyCode, i18n)}
                right={parsePrice(totalVatAmount, currencyCode, i18n)}
            />
        </>
    );
}

export default ReceiptVats;
