import { Button, styled, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useRef, useState } from 'react';
import ReactToPdf from 'react-to-pdf';
import { useTranslation } from 'react-i18next';
import { Purchase } from '../../types/purchase';
import ReceiptContent from './receiptcontent/ReceiptContent';
import Email from './email/Email';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Container = styled('div')`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    float: right;
    top: ${({ theme }) => theme.spacing(1)};
`;

const ReceiptWrapper = styled('div')<{ width: number }>`
    max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
    margin-top: ${({ theme, width }) =>
        // Small screen minus padding
        width < theme.breakpoints.values.sm + parseInt(theme.spacing(4), 10)
            ? theme.spacing(4)
            : theme.spacing(8)};
    margin-bottom: ${({ theme, width }) =>
        // Small screen minus padding
        width < theme.breakpoints.values.sm + parseInt(theme.spacing(4), 10)
            ? theme.spacing(4)
            : theme.spacing(8)};
    position: relative;
    align-self: center;
`;

type ReceiptProps = {
    purchase: Purchase;
};

function Receipt({ purchase }: ReceiptProps) {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const ref = useRef<HTMLDivElement>(null);
    const [pdfResolution, setPdfResolution] = useState<{ height: number; width: number }>({
        height: 0,
        width: 0,
    });

    const filename = `receipt_${String(purchase.purchaseId?.substring(0, 8))}`;

    useEffect(() => {
        // Dividing the window dimensions by 3.75 sets the correct pdf dimensions (for some reason)
        setPdfResolution({
            height: (ref.current?.offsetHeight || 0) / 3.75,
            width: (ref.current?.offsetWidth || 0) / 3.75,
        });
    }, [ref.current?.offsetHeight, ref.current?.offsetWidth]);

    return (
        <Container>
            <ReceiptWrapper width={width}>
                <ReceiptContent purchase={purchase} ref={ref} />
                <ReactToPdf
                    targetRef={ref}
                    filename={filename}
                    options={{
                        // Use landscape orientation when width is higher than height
                        orientation: pdfResolution?.width > pdfResolution?.height ? 'l' : 'h',
                        format: [pdfResolution.width, pdfResolution.height],
                    }}
                >
                    {({ toPdf }: never) => (
                        <StyledButton
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            onClick={toPdf}
                        >
                            <Typography>{t('downloadPdf')}</Typography>
                        </StyledButton>
                    )}
                </ReactToPdf>
            </ReceiptWrapper>
            <Email purchaseId={purchase.purchaseId} />
        </Container>
    );
}

export default Receipt;
