import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import defaultTheme from './themes/default';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import Home from './views/Home';
import SnackbarProvider from './components/SnackbarProvider';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './config';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);

function App() {
    const theme = createTheme(
        defaultTheme({
            primaryColor: PRIMARY_COLOR as string,
            secondaryColor: SECONDARY_COLOR as string,
        })
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <CssBaseline />
                    <Home />
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
