import { styled, TextFieldProps, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';
import { API_ORIGIN } from '../config';
import HTTPRequest from '../api/HTTPRequest';
import { Purchase } from '../types/purchase';
import Receipt from './receipt/Receipt';
import LanguageSelector from './language/LanguageSelector';
import Search from '../components/Search';

const REFERENCE_NAME = 'reference';

const Container = styled('div')`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled('div')`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledTypography = styled(Typography)`
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [referenceValue, setReferenceValue] = useState<string | null>(null);
    const [purchase, setPurchase] = useState<Purchase | null>(null);

    const inputRef = useRef<TextFieldProps>(null);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const fetchPurchase = (refValue: string) => {
        // Reset states
        setIsLoading(true);
        setHasError(false);
        setReferenceValue(refValue);
        setPurchase(null);

        const path = '/receipt/'?.concat(refValue);

        HTTPRequest({
            path,
            origin: API_ORIGIN,
        })
            .then(data => {
                if (!data || !data.products) {
                    throw new Error();
                }

                setPurchase(data);

                // Set the new search query
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${REFERENCE_NAME}=${refValue}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            })
            .catch(e => {
                // There might be something wrong on the server or user inserted invalid reference
                if (e.status === 400 || e.status === 404) {
                    // Bad request (invalid reference number)
                    setHasError(true);
                } else {
                    // Something on the server went wrong
                    enqueueSnackbar(t('snackbarSearchReferenceFailed'), { variant: 'error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmit = useCallback(() => {
        const refValue = inputRef.current?.value?.toString()?.trim();
        if (!refValue?.length) return;

        fetchPurchase(refValue);
    }, [inputRef.current?.value]);

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const initRefValue = params?.get(REFERENCE_NAME);

        // Only set init reference if non-empty string
        if (typeof initRefValue === 'string' && initRefValue.length) {
            if (inputRef.current) {
                inputRef.current.value = initRefValue;
            }
            fetchPurchase(initRefValue);
        }
    }, []);

    return (
        <Container>
            <Header>
                <LanguageSelector />
            </Header>
            <Typography variant="h2">{t('landingTitle')}</Typography>
            <StyledTypography variant="body1">{t('landingDescription')}</StyledTypography>
            <Search
                textFieldLabel={t('landingTextFieldLabel')}
                buttonLabel={t('landingButtonText')}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                hasError={hasError}
                helperText={t('landingInvalidReferenceLabel')}
                ref={inputRef}
                icon={<SearchIcon />}
            />
            {!isLoading && !hasError && purchase && referenceValue && (
                <Receipt purchase={purchase} />
            )}
        </Container>
    );
}

export default Home;
