import { styled, TextFieldProps, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useRef, useState } from 'react';
import validator from 'validator/es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { POST } from '../../../types/requestMethod';
import { SERVER_ORIGIN } from '../../../config';
import HTTPRequest from '../../../api/HTTPRequest';
import Search from '../../../components/Search';

const StyledTypography = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

type EmailProps = {
    purchaseId?: string;
};

function Email({ purchaseId }: EmailProps) {
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const inputRef = useRef<TextFieldProps>(null);

    // this function is called when the user requests to send the receiptcontent to the supplied emailaddress.
    const handleSubmit = useCallback(() => {
        const emailAddress = inputRef.current?.value?.toString();

        // Check if given email is valid
        if (!validator.isEmail(emailAddress, [])) {
            setInvalidEmail(true);
            return;
        }

        setInvalidEmail(false);
        setIsLoading(true);

        HTTPRequest({
            path: '/api/email/action-send/receipt',
            origin: SERVER_ORIGIN,
            method: POST,
            body: { emailAddress, purchaseId, language: i18n.resolvedLanguage },
        })
            .then(() => {
                enqueueSnackbar(t('snackbarEmailSuccess'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(t('snackbarPageEmailFailed'), { variant: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [purchaseId, inputRef, i18n, window, document]);

    return (
        <>
            <StyledTypography variant="body1">{t('receiptPageEmailDescription')}</StyledTypography>
            <Search
                textFieldLabel={t('receiptPageEmailFieldLabel')}
                buttonLabel={t('receiptPageSendButtonLabel')}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                hasError={invalidEmail}
                helperText={t('receiptPageErrorTitle')}
                ref={inputRef}
                icon={<SendIcon />}
            />
        </>
    );
}

export default Email;
