/* eslint-disable @typescript-eslint/naming-convention */
import tinycolor from 'tinycolor2';

type DefaultThemeProps = {
    primaryColor: string;
    secondaryColor: string;
};

function defaultTheme({ primaryColor, secondaryColor }: DefaultThemeProps) {
    const info = '#9013FE';
    const warning = '#FFC260';
    const success = '#43a047';
    const error = '#f44336';

    const primary = `#${primaryColor}`;
    const secondary = `#${secondaryColor}`;
    const background = `#${primaryColor}0b`;

    const darkenRate = 15;
    const lightenRate = 7.5;

    return {
        palette: {
            primary: {
                contrastText: '#fff',
                main: primary,
                light: tinycolor(primary).lighten(lightenRate).toHexString(),
                dark: tinycolor(primary).darken(darkenRate).toHexString(),
            },
            secondary: {
                main: secondary,
                light: tinycolor(secondary).lighten(lightenRate).toHexString(),
                dark: tinycolor(secondary).darken(darkenRate).toHexString(),
                contrastText: '#FFFFFF',
            },
            warning: {
                main: warning,
                light: tinycolor(warning).lighten(lightenRate).toHexString(),
                dark: tinycolor(warning).darken(darkenRate).toHexString(),
            },
            success: {
                main: success,
                light: tinycolor(success).lighten(lightenRate).toHexString(),
                dark: tinycolor(success).darken(darkenRate).toHexString(),
            },
            error: {
                main: error,
                light: tinycolor(error).lighten(lightenRate).toHexString(),
                dark: tinycolor(error).darken(darkenRate).toHexString(),
            },
            info: {
                main: info,
                light: tinycolor(info).lighten(lightenRate).toHexString(),
                dark: tinycolor(info).darken(darkenRate).toHexString(),
            },
            text: {
                primary: '#4A4A4A',
                secondary: '#6E6E6E',
                strikethrough: '#4A4A4A80',
                hint: '#B9B9B9',
            },
            background: {
                default: background,
                light: background,
            },
        },
        customShadows: {
            widget: '0px 3px 11px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            widgetDark:
                '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            widgetWide:
                '0px 12px 33px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        },
        typography: {
            h1: {
                fontSize: '2.8rem',
            },
            h2: {
                fontSize: '2rem',
            },
            h3: {
                fontSize: '1.64rem',
            },
            h4: {
                fontSize: '1.5rem',
            },
            h5: {
                fontSize: '1.285rem',
            },
            h6: {
                fontSize: '1.142rem',
            },
        },
        breakpoints: {
            values: {
                xs: 300,
                sm: 500, // Changed 600 to 500
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        components: {
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#4A4A4A1A',
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        boxShadow:
                            '0px 3px 11px 0px #10101012, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    button: {
                        '&:hover, &:focus': {
                            backgroundColor: background,
                        },
                    },
                    root: {
                        '&$selected': {
                            backgroundColor: `${background} !important`,
                            '&:focus': {
                                backgroundColor: background,
                            },
                        },
                    },
                },
            },
            MuiTouchRipple: {
                styleOverrides: {
                    child: {
                        backgroundColor: 'white',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        height: 56,
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(224, 224, 224, .5)',
                    },
                    head: {
                        fontSize: '0.95rem',
                    },
                    body: {
                        fontSize: '0.95rem',
                    },
                    sizeSmall: {
                        padding: '2px 24px 0 16px',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        position: 'absolute',
                        bottom: '-20px',
                    },
                    contained: {
                        margin: '4px 10px 0',
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: '#B9B9B9',
                    },
                    select: {
                        '.MuiListItemText-root': {
                            margin: '0 0 -1px 0',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    sizeSmall: {
                        fontSize: '0.84rem',
                        '.MuiTypography-root': {
                            fontSize: '0.84rem',
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                            paddingRight: '36px',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    outlined: {
                        '&.MuiInputLabel-sizeSmall': {
                            fontSize: '0.84rem',
                        },
                        '&.MuiInputLabel-sizeSmall.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -7px) scale(0.75)',
                        },
                    },
                },
            },
        } as const, // Used to resolve "Type is not assignable to 'CSSObject'" error
    };
}

export default defaultTheme;
