import { css, styled, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { forwardRef, ReactNode } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

const ReferenceInputWrapper = styled('div')<{ width: number }>`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};

    ${({ width, theme }) =>
        width < theme.breakpoints.values.xs &&
        css`
            flex-direction: column;
        `}
`;

const StyledTextField = styled(TextField)`
    background-color: white;
`;

const StyledLoadingButton = styled(LoadingButton)`
    min-width: fit-content;
    width: fit-content;
    white-space: nowrap;
    flex-grow: 1;
`;

type SearchProps = {
    textFieldLabel?: string;
    buttonLabel?: string;
    handleSubmit?: () => void;
    isLoading?: boolean;
    hasError?: boolean;
    helperText?: string;
    icon?: ReactNode;
};

const Search = forwardRef(
    (
        {
            textFieldLabel,
            buttonLabel,
            handleSubmit,
            isLoading,
            hasError,
            helperText,
            icon,
        }: SearchProps,
        ref
    ) => {
        const { width } = useWindowDimensions();

        return (
            <ReferenceInputWrapper width={width}>
                <StyledTextField
                    fullWidth
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                    label={textFieldLabel}
                    autoComplete="off"
                    onKeyDown={e => e.key === 'Enter' && handleSubmit && handleSubmit()}
                    helperText={!isLoading && hasError ? helperText : ''}
                    error={!isLoading && hasError}
                />
                <StyledLoadingButton
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"
                    endIcon={icon}
                    onClick={() => handleSubmit && handleSubmit()}
                >
                    <Typography>{buttonLabel}</Typography>
                </StyledLoadingButton>
            </ReferenceInputWrapper>
        );
    }
);

export default Search;
